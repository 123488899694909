import { AppBar, Box, ButtonBase, Grid, Link, Typography } from '@material-ui/core'
import SearchBar from 'components/SearchBar'
import { SearchBarProps } from 'components/SearchBar/SearchBar'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from '../../utils/TestIDs'

export interface HeaderProps {
    onLogoClick: () => void
    helpNumber: string
    searchBarProps?: SearchBarProps
}

const Header = (props: HeaderProps): JSX.Element => {
    const { t } = useTranslation()
    const { onLogoClick, helpNumber, searchBarProps } = props
    const enableSearchbar = false

    return (
        <AppBar position={'static'} {...TestIDs.GET_BASE('HEADER')}>
            <Grid container spacing={2} alignItems={'center'} justify={'space-between'}>
                <Grid item sm={searchBarProps ? 12 : true} md={searchBarProps ? 'auto' : true} xs={12}>
                    <Box
                        width={1}
                        display={'flex'}
                        justifyContent={searchBarProps ? 'center' : { xs: 'center', sm: 'flex-start' }}
                        alignItems={'center'}
                    >
                        <ButtonBase onClick={onLogoClick} disableRipple disableTouchRipple>
                            <img
                                src={process.env.PUBLIC_URL + '/customize/logo.png'}
                                alt={'placeholder'}
                                style={{ maxWidth: 210, width: '100%' }}
                            />
                        </ButtonBase>
                    </Box>
                </Grid>
                {searchBarProps && enableSearchbar && (
                    <Grid container item md xs={12} alignItems={'center'} justify={'center'}>
                        <Box width={{ xs: 1, md: 0.5 }} maxWidth={{ xs: undefined, sm: '300px' }}>
                            <SearchBar {...searchBarProps} />
                        </Box>
                    </Grid>
                )}
                {helpNumber.length > 0 && (
                    <Grid item md={'auto'} sm={searchBarProps ? 12 : 'auto'} xs={12}>
                        <Box width={1} display={'flex'} justifyContent={{ xs: 'center', md: undefined }}>
                            <Box
                                paddingTop={2}
                                paddingBottom={2}
                                paddingLeft={3}
                                paddingRight={3}
                                display={'flex'}
                                alignItems={'center'}
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                justifyContent={{ xs: undefined, sm: 'center', md: undefined }}
                                width={'fit-content'}
                            >
                                <Typography variant={'subtitle1'}>{t('header.help')}</Typography>
                                <Box display={'flex'}>
                                    <img
                                        src={process.env.PUBLIC_URL + '/customize/hand.svg'}
                                        alt={'->'}
                                        height={'20px'}
                                        style={{ marginLeft: 4, marginRight: 4 }}
                                    />
                                    <Link color={'inherit'} href={'tel:' + helpNumber.trim()}>
                                        <Typography variant={'subtitle1'} noWrap>
                                            {helpNumber}
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </AppBar>
    )
}

export default Header
